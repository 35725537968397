/* navbar styles */
.navbar {
  width: 100%;
  /*  background: #effaf0; */
  background: rgb(242,245,239);
  padding: 20px 20px;
  box-sizing: border-box;
}

.footer {
  width: 100%;
  background: rgb(242,245,239);
  padding: 20px 20px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}
.footer p{
  font-size: 14px;
}
.navbar ul {
  display: flex;
  margin: 0 auto;
  max-width: 1200px;
  align-items: center;
}
.title {
  margin-right: auto;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 1.2em;
}
.navbar button, .navbar a {
  margin-left: 16px;
}
.navbar a {
  color: #333;
  text-decoration: none;
}

.button_home{
  background: none;
  border: 2px solid #1f9751;
  padding: 6px 12px;
  border-radius: 4px;
  color: #1f9751;
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}