.container {
  /* display: flex; */
  /* grid-template-columns: 1fr 1fr; */
  /* max-width: 960px; */
  /* margin: 60px; */
  /* width: 100%; */
  align-items: center;
}

.trips_wrapper{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 20px;
  box-sizing: border-box;
  justify-content: stretch;
  flex: auto
}

.trips_container{
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  margin: 0 auto;
  max-width: 1100px;
  height:100%
  /* max-width: 1200px; */
  /* align-items: center; */
}

.content {
  /* padding: 50px; */
    display:flex;
    justify-content: center;
    height: 100%;
    align-items: start;
}
.sidebar {
  padding-left: 30px;
}
.sidebar form {
  padding: 20px;
  background: #1f9751;
  border-radius: 10px;
}
.sidebar input, .sidebar select {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 8px;
  box-sizing: border-box;
  border: 0;
  border-radius: 4px;
  color: #555;
  font-size: 1em;
}
.sidebar label {
  margin: 0 auto 20px;
  display: block;
  color: #fff;
}
.sidebar button {
  color: #fff;
  border: 2px solid #fff;
  padding: 6px 12px;
  background-color: transparent;
  font-size: 1em;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  width: 100%;
}
.sidebar h3 {
  color: #1f9751;
  margin-bottom: 20px;
}
.sidebar aside {
  margin-top: 40px;
  color: #555;
}
.sidebar aside li {
  margin: 10px;
}

.transactions{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-rows: repeat(3, 1fr); */
  gap: 10px;
  align-items: center; 

  /* background-image: url(../../assets/canoe.jpg); */
}

.li_wrapper:hover{
  /* height: 100px; */
}
/* transactions */
.transactions li {
  margin: 30px ;
  border: 2px solid #f2f2f2;
  /* background-color: #555;  */
  /* box-shadow: 3px 3px 5px rgba(50,50,50,0.1); */
  /* background-image: url(../../assets/canoe.jpg);
  background-size: cover; */
  /* padding: 20px; */
  /* display: flex; */
  /* align-items: center; */
  /* position: relative; */
  /* overflow: hidden; */
  border-radius: 10px;

  
  /* border-left: 4px solid #1f9751; */

  width: 300px;
  height: 300px;
}
.transactions .name {
  color: #777;
  font-size: 0.8em;
}
.transactions .amount {
  margin-left: auto;
  margin-right: 40px;
  color: #777;
  font-weight: bold;
  font-size: 0.8em;
}
.transactions button {
  position: absolute;
  top: 0;
  right:0;
  background: #ddd;
  color: #777;
  border: none;
  padding: 12px 8px;
  text-align: center;
  line-height: 0;
  font-size: 0.9em;
  cursor: pointer;
}


.wrapper{
	background-color: rgb(255,255,255);
	/* width: 300px; */
  /* width: 340px; */
  width: 100%;
	height: 220px;
	border: none;
	/* margin: 15px; */
  /* margin: 30px; */
	cursor: pointer;

  align-self: center;
  justify-self: center;

  /* font-family: 'Overpass Mono'; */
  font-family: "Andale Mono", AndaleMono, monospace;
  font-style: normal;
  font-weight: 50px;
	/* font-weight: 400; */
  /* font-weight: "normal" */
/*	border-radius: 10px;*/

}

.wrapper h1{
  font-style: normal;
  
}

.wrapper:hover .image_wrapper{
	height: 35%;
} 
.wrapper:hover .info_wrapper{
	height: 65%;
}

.wrapper:hover p{
	opacity: 1;
	transition: 0.5s;
	margin: 0;
	top: 0;
}



.image_wrapper{
	
	background-size: 140%,100%;
	background-position: center, center;
	height: 75%;
	width: 100%;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	transition: height 0.5s;
	display: flex;
	
}

.price_wrapper{
	width: 50%;
	height: 20%;
	position:relative;
}

.price{
	line-height: 25px;
	padding-left:15px;
	padding-right:15px;
	border-radius: 25px;
	height: 22px;
	width: max-content;
	background-color: rgba(40, 137, 67, 0.80);
	text-align: center;
	position: absolute;
	top: 8px;
	left: 8px;
	display: flex;
  align-items: center;
}

.participants_wrapper{
	width: 50%;
	height: 20%;
	position:relative;
}

.participants{
	line-height: 25px;
	padding-left:2px;
	padding-right:15px;

	border-radius: 25px;
	height: 22px;
	width: max-content;
	background-color: rgba(40, 137, 67);
  opacity: 0.8;
	text-align: center;
	position: absolute;
	top: 8px;
	right: 8px;
  display: flex;
  align-items: center;
}

.info_wrapper{
	background-color: rgb(242,245,239);
	height: 25%;
	width: 100%;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	transition: height 0.5s;
	overflow: hidden;

	display: flex;
  flex-flow: row wrap;
	padding-top: 10px;
	
}

.info_wrapper_left{
  display:flex table-row;
  justify-content: center;
  width: 60%;
  height: 55px;
}

.info_wrapper_right{
  width: 40%;
  height: 55px;
  margin-top:5px;
}

.wrapper h1, h2{
	margin: 0px;
	
	font-size: 18px;

	
	padding-left: 10px;
	
}
.wrapper h1{
	width: 50%;
}

.wrapper h2{
	font-size: 16px;
	width: 50%;
}

.wrapper h3{
	margin: 0px;
	font-size: 14px;
}

.date{
	padding: 2px 0;
	padding-right: 10px;
	text-align: right;
	width: 44%;
	height: 28px;
}

.wrapper p{
	opacity: 1;
	transition: 0.4s;
	font-size: 12px;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 0px;

	margin: 0px;

	
}

.wrapper span{
	color: #FFFFFF;
	height: max-content;

}

.content_wrapper{
  margin: 0;
  padding: 0;
  /* padding-left: 10%; */
  align-items: center;

  justify-content: center;

  /* width: 80%; */
  /* width: 1130px; */
  width: 100%;
  
  display: grid;
  /* grid-template-columns: repeat(3, 1fr); */
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  grid-auto-flow: dense;  
  gap: 30px;
  margin-bottom: 30px;
}