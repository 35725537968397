.trip_wrapper{
    width: 100%;
    padding: 20px 20px;
    box-sizing: border-box;

    justify-content: stretch;
    flex: auto;
  }
  
  .trip_container{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1100px;
    /* align-items: center; */
  }
  
  .basic_info_wrapper{
    


	background-color: rgb(242,245,239);
	height: 80px;
	width: 100%;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	transition: height 0.5s;
	overflow: hidden;

	display: flex;
	flex-wrap: wrap;
	align-items: right;
	
}

.trip_details{
    margin:0;
}

.trip_details h2, p{
    padding: 0;
}

.trip_info_left{
    position: sticky;
    position: -webkit-sticky;
    top: 45px;
    height: 600px;
    width: 40%;
    margin-top: 20px;
    margin-right: 30px;
}

.trip_info_right{
    width: 60%;
}

.trip_info_wrapper{
    display: flex;
    height: auto;
    margin-top: 25px;
    margin-left:15px;
    margin-right:15px
}

@media (max-width: 800px) {
  .trip_info_wrapper {
    flex-direction: column;
  }
  .trip_info_left{
    position: initial;
    height: auto;
    width: 100%;
  }
  .trip_info_right{
    width: 100%;
}

}

.trip_info_wrapper ul{
    list-style-type: disc;
    padding-left: 25px;
}

.trip_info_wrapper h2{
    padding-top: 15px;
}

.trip_info_wrapper p{
    font-size: 0.9em;
}

/* The Modal (background) */
.modal {
    display: flex; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    /* padding-top: 100px; Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    
  }
  
  /* Modal Content */
  .modal_content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 60%;
    display: flex;
    flex-direction: column;
  }
  
  /* The Close Button */
  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

  .btn_close{
    color: black;
    border: none;
    border-bottom: solid 1px;
    border-color: black;
    background: none;
    align-self: end;
  }

  input:focus {
    outline-width: 0;
}