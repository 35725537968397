.signup-form {
  max-width: 360px;
  margin: 60px auto;
  padding: 20px;
}
.signup-form label {
  display: block;
  margin: 30px auto;
}
.signup-form span {
  display: block;
  margin-bottom: 6px;
}
.signup-form input {
  padding: 8px 6px;
  font-size: 1em;
  color: #777;
  width: 100%;
}