.landing_wrapper {
    width: 100%;
    padding: 20px 20px;
    box-sizing: border-box;

    justify-content: stretch;
    flex: auto;
    /* background: rgb(113,150,211);
background: linear-gradient(176deg, rgba(113,150,211,1) 0%, rgba(56,60,33,1) 0%, rgba(0,212,255,1) 100%); */
}

.landing_container{
    /* height:100%; */
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1200px;
    align-items: center;
    justify-content: center;
}

.landing_container h1{
    text-align: justify;
    /* overflow:hidden; */
    font-size: 167px;
    word-break: break-all;
    /* background: -webkit-linear-gradient(#b4e986, #6bbcf3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
  
    line-height: 140px;
     font-weight: 1000;
     text-transform: uppercase;
     background: url(https://firebasestorage.googleapis.com/v0/b/letsgotripping-47a2a.appspot.com/o/lets_go_tripping.JPEG?alt=media&token=416523e5-69c7-454b-ac82-817c4e7b3e9d);
     /* background-image: fit; */
     background-size: cover;
     background-position: center, center; 
     color: #de466c;
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
     /* filter:blur(0.5px) */

     background-attachment: fixed;
  }

.navbar {
    width: 100%;
    background: #effaf0;
    padding: 20px 20px;
    box-sizing: border-box;
  }
  .navbar ul {
    display: flex;
    margin: 0 auto;
    max-width: 1200px;
    align-items: center;
  }
  .title {
    margin-right: auto;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 1.2em;
  }
  .navbar button, .navbar a {
    margin-left: 16px;
  }
  .navbar a {
    color: #333;
    text-decoration: none;
  }
  
  .button_home{
    background: none;
    border: 2px solid #1f9751;
    padding: 6px 12px;
    border-radius: 4px;
    color: #1f9751;
    font-weight: bold;
    cursor: pointer;
    font-size: 1em;
  }

  .btn_landing{
    background:none;
    border:solid 1px;
    border-radius: 20px;
    border-color:white;
    color:white;
    font-size:30px;
    font-family:Poppins, sans-serif;
    margin-top:50px;
    padding: 15px;
  }

  .btn_landing:hover{
    background: rgba(56,60,33,1);
    border:solid 1px;
    border-radius: 20px;
    border-color:white;
    color:black;
    font-size:30px;
    font-family:Poppins, sans-serif;
    margin-top:50px;
    padding: 15px;
  }

  .btn_landing h2{
    text-align: center;
    color: black;
    font-size: 40px;
    -webkit-text-fill-color: rgba(255, 255, 255, 1); /* Will override color (regardless of order) */
    -webkit-text-stroke: 0.5px rgb(255, 255, 255);
}

.btn_landing:hover h2{
    text-align: center;
    color: black;
    font-size: 40px;
    -webkit-text-fill-color: rgba(255, 255, 255, 0); /* Will override color (regardless of order) */
    -webkit-text-stroke: 1.5px rgb(255, 255, 255);
}