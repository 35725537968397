@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Overpass%20Mono);
/* base styles */
html{
  height: 100%;
  
}
body {
  font-family: Poppins, sans-serif;
  margin: 0;
  font-size: 1.1em;
  height: 100%;
  background-color:white;
}

#root{
  height: 100%;
  background-color:white;
}
.App{
  height: 100%;
  margin:0;
  display: flex;
  flex-direction: column;
  background-color:white;
}
ul, li, p, h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}
ul {
  list-style-type: none;
}
.btn {
  background: none;
  border: 2px solid;
  border-color: rgba(40, 137, 67, 1);
  /* border: 2px solid #1f9751; */
  padding: 6px 12px;
  /* border-radius: 4px; */
  border-radius:20px;
  color: rgba(40, 137, 67, 1);
  /* color: #1f9751; */
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}
.btn:hover {
  background: rgba(40, 137, 67, 1);
  /* background: #1f9751; */
  color: #fff;
}

.btn_white {
  background: none;
  border: 2px solid;
  border-color: white;
  /* border: 2px solid #1f9751; */
  padding: 6px 12px;
  border-radius:20px;
  color: white;
  /* color: #1f9751; */
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}
.btn_white:hover {
  background: white;
  /* background: #1f9751; */
  color: black;
}

.btn_hover{
  background: rgba(40, 137, 67, 1);
  border: 2px solid;
  border-color: rgba(40, 137, 67, 1);
  /* border: 2px solid #1f9751; */
  padding: 6px 12px;
  border-radius:20px;
  color: #fff;
  /* color: #1f9751; */
  font-weight: bold;
  cursor: default;
  font-size: 1em;
}

.start_screen_container{
  display: flex;
  align-items: center;
  justify-content: center;
  color:white;
  width:100%;
  height:100%;
  background-color:black;
  overflow: hidden;
  margin:0
}

.start_screen_container h1{
  text-align: center;
  font-size: 150px;
  /* background: -webkit-linear-gradient(#b4e986, #6bbcf3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */

  line-height: 120px;
   font-weight: 800;
   text-transform: uppercase;
   background: url(https://firebasestorage.googleapis.com/v0/b/letsgotripping-47a2a.appspot.com/o/trips%2Fhiking.JPG?alt=media&token=75982364-ee83-4c02-b7d3-c1140b328797);
   /* background-image: fit; */
   background-size: cover;
   background-position: center, center; 
   color: #de466c;
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
}

.start_screen_container input:focus{
  outline: none;
}
.Home_container__3bftD {
  width: 100%;
    padding: 20px 20px;
    box-sizing: border-box;

    justify-content: stretch;
    flex: auto;
}

.Home_home_wrapper__1JXuw{
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1100px;
}

.Home_content__18qU0 {
  margin-top:30px;
  margin-bottom:30px;
  width: 100%;
  /* margin: 0 auto; */
}
.Home_sidebar__21xz5 {
  padding-left: 30px;
}
.Home_sidebar__21xz5 form {
  padding: 20px;
  background: #1f9751;
  border-radius: 10px;
}
.Home_sidebar__21xz5 input, .Home_sidebar__21xz5 select {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 8px;
  box-sizing: border-box;
  border: 0;
  border-radius: 4px;
  color: #555;
  font-size: 1em;
}
.Home_sidebar__21xz5 label {
  margin: 0 auto 20px;
  display: block;
  color: #fff;
}
.Home_sidebar__21xz5 button {
  color: #fff;
  border: 2px solid #fff;
  padding: 6px 12px;
  background-color: transparent;
  font-size: 1em;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  width: 100%;
}
.Home_sidebar__21xz5 h3 {
  color: #1f9751;
  margin-bottom: 20px;
}
.Home_sidebar__21xz5 aside {
  margin-top: 40px;
  color: #555;
}
.Home_sidebar__21xz5 aside li {
  margin: 10px;
}

/* transactions */
.Home_transactions__3_tqr li {
  margin: 30px auto;
  border: 1px solid #f2f2f2;
  box-shadow: 3px 3px 5px rgba(50,50,50,0.1);
  padding: 20px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  border-left: 4px solid #1f9751;
}
.Home_transactions__3_tqr .Home_name__4a_Tj {
  color: #777;
  font-size: 1.4em;
}
.Home_transactions__3_tqr .Home_amount__1MW_9 {
  margin-left: auto;
  margin-right: 40px;
  color: #777;
  font-weight: bold;
  font-size: 1.6em;
}
.Home_transactions__3_tqr button {
  position: absolute;
  top: 0;
  right:0;
  background: #ddd;
  color: #777;
  border: none;
  padding: 12px 8px;
  text-align: center;
  line-height: 0;
  font-size: 0.9em;
  cursor: pointer;
}

.Home_trips_container__3FMVj{
  display:flex;
  justify-content: center;
  /* margin-top:30px; */
  margin-bottom:30px;
}
.Trips_container__3Y1zA {
  /* display: flex; */
  /* grid-template-columns: 1fr 1fr; */
  /* max-width: 960px; */
  /* margin: 60px; */
  /* width: 100%; */
  align-items: center;
}

.Trips_trips_wrapper__1MfSm{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 20px;
  box-sizing: border-box;
  justify-content: stretch;
  flex: auto
}

.Trips_trips_container__1LeRr{
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  margin: 0 auto;
  max-width: 1100px;
  height:100%
  /* max-width: 1200px; */
  /* align-items: center; */
}

.Trips_content__1JZUF {
  /* padding: 50px; */
    display:flex;
    justify-content: center;
    height: 100%;
    align-items: start;
}
.Trips_sidebar__BAWFh {
  padding-left: 30px;
}
.Trips_sidebar__BAWFh form {
  padding: 20px;
  background: #1f9751;
  border-radius: 10px;
}
.Trips_sidebar__BAWFh input, .Trips_sidebar__BAWFh select {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 8px;
  box-sizing: border-box;
  border: 0;
  border-radius: 4px;
  color: #555;
  font-size: 1em;
}
.Trips_sidebar__BAWFh label {
  margin: 0 auto 20px;
  display: block;
  color: #fff;
}
.Trips_sidebar__BAWFh button {
  color: #fff;
  border: 2px solid #fff;
  padding: 6px 12px;
  background-color: transparent;
  font-size: 1em;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  width: 100%;
}
.Trips_sidebar__BAWFh h3 {
  color: #1f9751;
  margin-bottom: 20px;
}
.Trips_sidebar__BAWFh aside {
  margin-top: 40px;
  color: #555;
}
.Trips_sidebar__BAWFh aside li {
  margin: 10px;
}

.Trips_transactions__w7Km1{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-rows: repeat(3, 1fr); */
  grid-gap: 10px;
  gap: 10px;
  align-items: center; 

  /* background-image: url(../../assets/canoe.jpg); */
}

.Trips_li_wrapper__3zy6y:hover{
  /* height: 100px; */
}
/* transactions */
.Trips_transactions__w7Km1 li {
  margin: 30px ;
  border: 2px solid #f2f2f2;
  /* background-color: #555;  */
  /* box-shadow: 3px 3px 5px rgba(50,50,50,0.1); */
  /* background-image: url(../../assets/canoe.jpg);
  background-size: cover; */
  /* padding: 20px; */
  /* display: flex; */
  /* align-items: center; */
  /* position: relative; */
  /* overflow: hidden; */
  border-radius: 10px;

  
  /* border-left: 4px solid #1f9751; */

  width: 300px;
  height: 300px;
}
.Trips_transactions__w7Km1 .Trips_name__1PeFC {
  color: #777;
  font-size: 0.8em;
}
.Trips_transactions__w7Km1 .Trips_amount__1sYTg {
  margin-left: auto;
  margin-right: 40px;
  color: #777;
  font-weight: bold;
  font-size: 0.8em;
}
.Trips_transactions__w7Km1 button {
  position: absolute;
  top: 0;
  right:0;
  background: #ddd;
  color: #777;
  border: none;
  padding: 12px 8px;
  text-align: center;
  line-height: 0;
  font-size: 0.9em;
  cursor: pointer;
}


.Trips_wrapper__24x7m{
	background-color: rgb(255,255,255);
	/* width: 300px; */
  /* width: 340px; */
  width: 100%;
	height: 220px;
	border: none;
	/* margin: 15px; */
  /* margin: 30px; */
	cursor: pointer;

  align-self: center;
  justify-self: center;

  /* font-family: 'Overpass Mono'; */
  font-family: "Andale Mono", AndaleMono, monospace;
  font-style: normal;
  font-weight: 50px;
	/* font-weight: 400; */
  /* font-weight: "normal" */
/*	border-radius: 10px;*/

}

.Trips_wrapper__24x7m h1{
  font-style: normal;
  
}

.Trips_wrapper__24x7m:hover .Trips_image_wrapper__3wQq2{
	height: 35%;
} 
.Trips_wrapper__24x7m:hover .Trips_info_wrapper__2YSCh{
	height: 65%;
}

.Trips_wrapper__24x7m:hover p{
	opacity: 1;
	transition: 0.5s;
	margin: 0;
	top: 0;
}



.Trips_image_wrapper__3wQq2{
	
	background-size: 140%,100%;
	background-position: center, center;
	height: 75%;
	width: 100%;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	transition: height 0.5s;
	display: flex;
	
}

.Trips_price_wrapper__2rw97{
	width: 50%;
	height: 20%;
	position:relative;
}

.Trips_price__1B37N{
	line-height: 25px;
	padding-left:15px;
	padding-right:15px;
	border-radius: 25px;
	height: 22px;
	width: -webkit-max-content;
	width: max-content;
	background-color: rgba(40, 137, 67, 0.80);
	text-align: center;
	position: absolute;
	top: 8px;
	left: 8px;
	display: flex;
  align-items: center;
}

.Trips_participants_wrapper__3kOKa{
	width: 50%;
	height: 20%;
	position:relative;
}

.Trips_participants__1YX1O{
	line-height: 25px;
	padding-left:2px;
	padding-right:15px;

	border-radius: 25px;
	height: 22px;
	width: -webkit-max-content;
	width: max-content;
	background-color: rgba(40, 137, 67);
  opacity: 0.8;
	text-align: center;
	position: absolute;
	top: 8px;
	right: 8px;
  display: flex;
  align-items: center;
}

.Trips_info_wrapper__2YSCh{
	background-color: rgb(242,245,239);
	height: 25%;
	width: 100%;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	transition: height 0.5s;
	overflow: hidden;

	display: flex;
  flex-flow: row wrap;
	padding-top: 10px;
	
}

.Trips_info_wrapper_left__VV6U0{
  display:flex table-row;
  justify-content: center;
  width: 60%;
  height: 55px;
}

.Trips_info_wrapper_right__6dyiL{
  width: 40%;
  height: 55px;
  margin-top:5px;
}

.Trips_wrapper__24x7m h1, h2{
	margin: 0px;
	
	font-size: 18px;

	
	padding-left: 10px;
	
}
.Trips_wrapper__24x7m h1{
	width: 50%;
}

.Trips_wrapper__24x7m h2{
	font-size: 16px;
	width: 50%;
}

.Trips_wrapper__24x7m h3{
	margin: 0px;
	font-size: 14px;
}

.Trips_date__EXKQp{
	padding: 2px 0;
	padding-right: 10px;
	text-align: right;
	width: 44%;
	height: 28px;
}

.Trips_wrapper__24x7m p{
	opacity: 1;
	transition: 0.4s;
	font-size: 12px;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 0px;

	margin: 0px;

	
}

.Trips_wrapper__24x7m span{
	color: #FFFFFF;
	height: -webkit-max-content;
	height: max-content;

}

.Trips_content_wrapper__SeAzg{
  margin: 0;
  padding: 0;
  /* padding-left: 10%; */
  align-items: center;

  justify-content: center;

  /* width: 80%; */
  /* width: 1130px; */
  width: 100%;
  
  display: grid;
  /* grid-template-columns: repeat(3, 1fr); */
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  grid-auto-flow: dense;  
  grid-gap: 30px;  
  gap: 30px;
  margin-bottom: 30px;
}
.Login_login-form__1NBYb {
  max-width: 360px;
  margin-left: 80px;
  padding: 20px;
}
.Login_login-form__1NBYb label {
  display: block;
  margin: 30px auto;
}
.Login_login-form__1NBYb span {
  display: block;
  margin-bottom: 6px;
}
.Login_login-form__1NBYb input {
  padding: 8px 6px;
  font-size: 1em;
  color: #777;
  width: 100%;
}
.Signup_signup-form__3B7tC {
  max-width: 360px;
  margin: 60px auto;
  padding: 20px;
}
.Signup_signup-form__3B7tC label {
  display: block;
  margin: 30px auto;
}
.Signup_signup-form__3B7tC span {
  display: block;
  margin-bottom: 6px;
}
.Signup_signup-form__3B7tC input {
  padding: 8px 6px;
  font-size: 1em;
  color: #777;
  width: 100%;
}
/* navbar styles */
.Navbar_navbar__31-FB {
  width: 100%;
  /*  background: #effaf0; */
  background: rgb(242,245,239);
  padding: 20px 20px;
  box-sizing: border-box;
}

.Navbar_footer__3X0uE {
  width: 100%;
  background: rgb(242,245,239);
  padding: 20px 20px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}
.Navbar_footer__3X0uE p{
  font-size: 14px;
}
.Navbar_navbar__31-FB ul {
  display: flex;
  margin: 0 auto;
  max-width: 1200px;
  align-items: center;
}
.Navbar_title__2CGsW {
  margin-right: auto;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 1.2em;
}
.Navbar_navbar__31-FB button, .Navbar_navbar__31-FB a {
  margin-left: 16px;
}
.Navbar_navbar__31-FB a {
  color: #333;
  text-decoration: none;
}

.Navbar_button_home__3UqGU{
  background: none;
  border: 2px solid #1f9751;
  padding: 6px 12px;
  border-radius: 4px;
  color: #1f9751;
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}
.Trip_trip_wrapper__3F22W{
    width: 100%;
    padding: 20px 20px;
    box-sizing: border-box;

    justify-content: stretch;
    flex: auto;
  }
  
  .Trip_trip_container__uv0bP{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1100px;
    /* align-items: center; */
  }
  
  .Trip_basic_info_wrapper__31enx{
    


	background-color: rgb(242,245,239);
	height: 80px;
	width: 100%;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	transition: height 0.5s;
	overflow: hidden;

	display: flex;
	flex-wrap: wrap;
	align-items: right;
	
}

.Trip_trip_details__17S8h{
    margin:0;
}

.Trip_trip_details__17S8h h2, p{
    padding: 0;
}

.Trip_trip_info_left__2u3KX{
    position: sticky;
    position: -webkit-sticky;
    top: 45px;
    height: 600px;
    width: 40%;
    margin-top: 20px;
    margin-right: 30px;
}

.Trip_trip_info_right__25PK7{
    width: 60%;
}

.Trip_trip_info_wrapper__xdeCZ{
    display: flex;
    height: auto;
    margin-top: 25px;
    margin-left:15px;
    margin-right:15px
}

@media (max-width: 800px) {
  .Trip_trip_info_wrapper__xdeCZ {
    flex-direction: column;
  }
  .Trip_trip_info_left__2u3KX{
    position: initial;
    height: auto;
    width: 100%;
  }
  .Trip_trip_info_right__25PK7{
    width: 100%;
}

}

.Trip_trip_info_wrapper__xdeCZ ul{
    list-style-type: disc;
    padding-left: 25px;
}

.Trip_trip_info_wrapper__xdeCZ h2{
    padding-top: 15px;
}

.Trip_trip_info_wrapper__xdeCZ p{
    font-size: 0.9em;
}

/* The Modal (background) */
.Trip_modal__1aLYv {
    display: flex; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    /* padding-top: 100px; Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    
  }
  
  /* Modal Content */
  .Trip_modal_content__1SjpB {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 60%;
    display: flex;
    flex-direction: column;
  }
  
  /* The Close Button */
  .Trip_close__FSPJT {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .Trip_close__FSPJT:hover,
  .Trip_close__FSPJT:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

  .Trip_btn_close__3mkBe{
    color: black;
    border: none;
    border-bottom: solid 1px;
    border-color: black;
    background: none;
    align-self: end;
  }

  input:focus {
    outline-width: 0;
}
.Landing_landing_wrapper__1FZvG {
    width: 100%;
    padding: 20px 20px;
    box-sizing: border-box;

    justify-content: stretch;
    flex: auto;
    /* background: rgb(113,150,211);
background: linear-gradient(176deg, rgba(113,150,211,1) 0%, rgba(56,60,33,1) 0%, rgba(0,212,255,1) 100%); */
}

.Landing_landing_container__1yXMp{
    /* height:100%; */
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1200px;
    align-items: center;
    justify-content: center;
}

.Landing_landing_container__1yXMp h1{
    text-align: justify;
    /* overflow:hidden; */
    font-size: 167px;
    word-break: break-all;
    /* background: -webkit-linear-gradient(#b4e986, #6bbcf3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
  
    line-height: 140px;
     font-weight: 1000;
     text-transform: uppercase;
     background: url(https://firebasestorage.googleapis.com/v0/b/letsgotripping-47a2a.appspot.com/o/lets_go_tripping.JPEG?alt=media&token=416523e5-69c7-454b-ac82-817c4e7b3e9d);
     /* background-image: fit; */
     background-size: cover;
     background-position: center, center; 
     color: #de466c;
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
     /* filter:blur(0.5px) */

     background-attachment: fixed;
  }

.Landing_navbar__1F12A {
    width: 100%;
    background: #effaf0;
    padding: 20px 20px;
    box-sizing: border-box;
  }
  .Landing_navbar__1F12A ul {
    display: flex;
    margin: 0 auto;
    max-width: 1200px;
    align-items: center;
  }
  .Landing_title__p2I3x {
    margin-right: auto;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 1.2em;
  }
  .Landing_navbar__1F12A button, .Landing_navbar__1F12A a {
    margin-left: 16px;
  }
  .Landing_navbar__1F12A a {
    color: #333;
    text-decoration: none;
  }
  
  .Landing_button_home__2JpyH{
    background: none;
    border: 2px solid #1f9751;
    padding: 6px 12px;
    border-radius: 4px;
    color: #1f9751;
    font-weight: bold;
    cursor: pointer;
    font-size: 1em;
  }

  .Landing_btn_landing__1fBFd{
    background:none;
    border:solid 1px;
    border-radius: 20px;
    border-color:white;
    color:white;
    font-size:30px;
    font-family:Poppins, sans-serif;
    margin-top:50px;
    padding: 15px;
  }

  .Landing_btn_landing__1fBFd:hover{
    background: rgba(56,60,33,1);
    border:solid 1px;
    border-radius: 20px;
    border-color:white;
    color:black;
    font-size:30px;
    font-family:Poppins, sans-serif;
    margin-top:50px;
    padding: 15px;
  }

  .Landing_btn_landing__1fBFd h2{
    text-align: center;
    color: black;
    font-size: 40px;
    -webkit-text-fill-color: rgba(255, 255, 255, 1); /* Will override color (regardless of order) */
    -webkit-text-stroke: 0.5px rgb(255, 255, 255);
}

.Landing_btn_landing__1fBFd:hover h2{
    text-align: center;
    color: black;
    font-size: 40px;
    -webkit-text-fill-color: rgba(255, 255, 255, 0); /* Will override color (regardless of order) */
    -webkit-text-stroke: 1.5px rgb(255, 255, 255);
}
.NewTrip_container__oFw3U{
    margin:50px auto;
    width: 70%

}

.NewTrip_container__oFw3U form {
    padding: 20px;
    border-radius: 10px;
}

.NewTrip_container__oFw3U label {
    margin: 0 auto 20px;
    display: block;
    
  }

.NewTrip_container__oFw3U input, .NewTrip_container__oFw3U select {
    display: block;
    width: 50%;
    padding: 10px;
    margin-top: 8px;
    box-sizing: border-box;
    border: none;
    border-bottom: solid 1px;
    /* border-radius: 4px; */
    color: #555;
    font-size: 1em;
  }

.NewTrip_container__oFw3U textarea{
  display: block;
    width: 70%;
    height: 150px;
    padding: 10px;
    margin-top: 8px;
    box-sizing: border-box;
    border: 1;
    border-radius: 4px;
    color: #555;
    font-size: 1em;
}

.NewTrip_container__oFw3U input::file-selector-button {
  background: none;
  border: 2px solid #1f9751;
  padding: 6px 12px;
  border-radius: 4px;
  color: #1f9751;
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}
.EditTrip_container__2nADu{
    margin:50px auto;
    width: 70%

}

.EditTrip_container__2nADu form {
    padding: 20px;
    border-radius: 10px;
}

.EditTrip_container__2nADu label {
    margin: 0 auto 20px;
    display: block;
    
  }

.EditTrip_container__2nADu input, .EditTrip_container__2nADu select {
    display: block;
    width: 50%;
    padding: 10px;
    margin-top: 8px;
    box-sizing: border-box;
    border: none;
    border-bottom: solid 1px;
    /* border-radius: 4px; */
    color: #555;
    font-size: 1em;
  }

.EditTrip_container__2nADu textarea{
  display: block;
    width: 70%;
    height: 150px;
    padding: 10px;
    margin-top: 8px;
    box-sizing: border-box;
    border: 1;
    border-radius: 4px;
    color: #555;
    font-size: 1em;
}

.EditTrip_container__2nADu input::file-selector-button {
  background: none;
  border: 2px solid #1f9751;
  padding: 6px 12px;
  border-radius: 4px;
  color: #1f9751;
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}

.EditTrip_container__2nADu ul{
    list-style-type: disc;
    padding-left: 25px;
}
