@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url("https://fonts.googleapis.com/css?family=Overpass%20Mono");

/* base styles */
html{
  height: 100%;
  
}
body {
  font-family: Poppins, sans-serif;
  margin: 0;
  font-size: 1.1em;
  height: 100%;
  background-color:white;
}

#root{
  height: 100%;
  background-color:white;
}
.App{
  height: 100%;
  margin:0;
  display: flex;
  flex-direction: column;
  background-color:white;
}
ul, li, p, h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}
ul {
  list-style-type: none;
}
.btn {
  background: none;
  border: 2px solid;
  border-color: rgba(40, 137, 67, 1);
  /* border: 2px solid #1f9751; */
  padding: 6px 12px;
  /* border-radius: 4px; */
  border-radius:20px;
  color: rgba(40, 137, 67, 1);
  /* color: #1f9751; */
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}
.btn:hover {
  background: rgba(40, 137, 67, 1);
  /* background: #1f9751; */
  color: #fff;
}

.btn_white {
  background: none;
  border: 2px solid;
  border-color: white;
  /* border: 2px solid #1f9751; */
  padding: 6px 12px;
  border-radius:20px;
  color: white;
  /* color: #1f9751; */
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}
.btn_white:hover {
  background: white;
  /* background: #1f9751; */
  color: black;
}

.btn_hover{
  background: rgba(40, 137, 67, 1);
  border: 2px solid;
  border-color: rgba(40, 137, 67, 1);
  /* border: 2px solid #1f9751; */
  padding: 6px 12px;
  border-radius:20px;
  color: #fff;
  /* color: #1f9751; */
  font-weight: bold;
  cursor: default;
  font-size: 1em;
}

.start_screen_container{
  display: flex;
  align-items: center;
  justify-content: center;
  color:white;
  width:100%;
  height:100%;
  background-color:black;
  overflow: hidden;
  margin:0
}

.start_screen_container h1{
  text-align: center;
  font-size: 150px;
  /* background: -webkit-linear-gradient(#b4e986, #6bbcf3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */

  line-height: 120px;
   font-weight: 800;
   text-transform: uppercase;
   background: url(https://firebasestorage.googleapis.com/v0/b/letsgotripping-47a2a.appspot.com/o/trips%2Fhiking.JPG?alt=media&token=75982364-ee83-4c02-b7d3-c1140b328797);
   /* background-image: fit; */
   background-size: cover;
   background-position: center, center; 
   color: #de466c;
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
}

.start_screen_container input:focus{
  outline: none;
}