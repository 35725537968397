.container{
    margin:50px auto;
    width: 70%

}

.container form {
    padding: 20px;
    border-radius: 10px;
}

.container label {
    margin: 0 auto 20px;
    display: block;
    
  }

.container input, .container select {
    display: block;
    width: 50%;
    padding: 10px;
    margin-top: 8px;
    box-sizing: border-box;
    border: none;
    border-bottom: solid 1px;
    /* border-radius: 4px; */
    color: #555;
    font-size: 1em;
  }

.container textarea{
  display: block;
    width: 70%;
    height: 150px;
    padding: 10px;
    margin-top: 8px;
    box-sizing: border-box;
    border: 1;
    border-radius: 4px;
    color: #555;
    font-size: 1em;
}

.container input::file-selector-button {
  background: none;
  border: 2px solid #1f9751;
  padding: 6px 12px;
  border-radius: 4px;
  color: #1f9751;
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}

.container ul{
    list-style-type: disc;
    padding-left: 25px;
}